import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { authSelectors } from '@ui/store/authStore';

export function useAuthRedirect() {
  const loggedIn = authSelectors.auth.use.isLoggedIn();
  const router = useRouter();
  // Router logic for Authenticated Pages
  useEffect(() => {
    const redirectAccountPages = (url: string) => {
      // Redirect login and sign up routes to the account page if the user is logged in
      if (url.startsWith('/account/login') || url.startsWith('/account/signup')) {
        if (loggedIn) {
          router.push('/account');
          return false;
        }
        // Redirect all other routes starting with `/account` to the login page if the user is not logged in
      } else if (url.startsWith('/account/reset/') || url.startsWith('/account/activate/')) {
        return false;
      } else if (url.startsWith('/account') && !loggedIn) {
        router.push('/account/login');
        return false;
      }
      if (url.startsWith('/returns/login') && loggedIn) {
        router.push('/account/returns');
        return false;
      }
      if (url.startsWith('/returns/account') && !loggedIn) {
        router.push('/returns/guest');
      }
    };

    // Check if we need to redirect on first load.
    redirectAccountPages(router.pathname);

    // Check if we need to redirect on every route change
    router.events.on('routeChangeStart', redirectAccountPages);

    // Turn off the event listener on unmount
    return () => {
      router.events.off('routeChangeStart', redirectAccountPages);
    };
  }, [router, loggedIn]);
}
