import React, { useRef, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Transition } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { getCookie, setCookie } from '@ui/hooks/useCookie';
import { default as getPersonalizedRecommendations } from '@ui/axios/searchSpring/personalizedRecommendations';
import { recommendationProductsToSearchProducts as mapToSearchProducts } from '@ui/helpers/recommendationProductsToSearchProducts';
import SearchForm from '../../forms/SearchForm/SearchForm';
import useCartStore from '@ui/store/cartStore';
import { AppContext } from '@ui/context/context';
import { ProductGrid } from '@ui/components/shared';
import { slideUpAfter } from './transitions';
import { useStaticContent } from '@ui/providers/static-content-provider';
import preflight from '@ui/axios/searchSpring/preflight';
import { default as getAutocomplete } from '@ui/axios/searchSpring/autocomplete';
import { default as getSuggestions } from '@ui/axios/searchSpring/suggest';
import { HistoryClockIcon, TrendingIcon, CompactSearchIcon, Modal, Container } from '@ui/components/core';
import Link from 'next/link';
import cn from '@ui/utils/cn';
import { useGetCart } from '@ui/hooks/useCartQuery';
import { GetProducts } from '@client-shopify/gql/storefront/api/queries';
import { getCustomerCountry } from '@ui/hooks/useCustomerCountry';
import env from '@ui/env';

type SearchModalProps = {
  opened: boolean;
  onClose: () => void;
};

const SearchModal = React.forwardRef(function SearchModal({ opened, onClose }: SearchModalProps, ref) {
  const router = useRouter();
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const { state } = React.useContext(AppContext);
  const cartID = useCartStore((state) => state?.cartId);
  const cartUpdatedKey = useCartStore((state) => state?.cartUpdatedKey);
  const [searchString, setSearchString] = React.useState<string>();
  const [searchData, setSearchData] = React.useState<Array<any>>();
  const [suggestData, setSuggestData] = React.useState<Awaited<ReturnType<typeof getSuggestions>>>();
  const [topSellerProducts, setTopSellerProducts] = React.useState<Array<any>>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAnimating, setIsAnimating] = React.useState(true);
  const [recentSearches, setRecentSearches] = React.useState('');
  const bestSellerCollectionId = useStaticContent('Collection.BestSellerId');
  const searchTrendingLinks = useStaticContent('Search.TrendingLinks');
  const searchHelpLinks = useStaticContent('Search.HelpLinks');
  const controllerRef = useRef<AbortController>();

  const runSuggestQuery = async ({ searchQuery }: { searchQuery: string }) => {
    const { data } = await getSuggestions({
      query: searchQuery,
      suggestionCount: 15,
    });
    // limit to 6 alternatives for the search results
    data.alternatives = data.alternatives.slice(0, 6);
    return {
      data,
    };
  };

  const runSearchQuery = async ({ searchQuery, signal }: { searchQuery: string; signal: AbortSignal }) => {
    const ssUserId = getCookie('ssUserId');
    const ssSessionIdNamespace = getCookie('ssSessionIdNamespace');
    const pageLoadId = getCookie('pageLoadId');
    const shopper = getCookie('shopper');
    const cart = getCookie('cart');
    const lastViewed = getCookie('lastViewed');

    await preflight({
      method: 'POST',
      userId: ssUserId,
      shopper,
      cart,
      lastViewed,
      signal,
    });

    const { data } = await getAutocomplete({
      query: searchQuery,
      ssUserId,
      ssSessionIdNamespace,
      pageLoadId,
      shopper,
      cart,
      lastViewed,
      resultsPerPage: 4,
      signal,
    });

    if (data.merchandising?.redirect) {
      router.push(data.merchandising.redirect);
    }

    return {
      data,
    };
  };

  const handleResults = (searchQuery: string) => {
    setSuggestData(undefined);
    setSearchData(undefined);

    if (!searchQuery.trim().length) {
      setSearchString('');
      setSearchData(undefined);
      setIsLoading(false);
      return;
    }

    controllerRef.current = new AbortController();
    const signal = controllerRef.current?.signal;

    (async (searchQuery: string) => {
      try {
        setIsLoading(true);
        const { data: returnedSuggestData } = await runSuggestQuery({ searchQuery });
        if (returnedSuggestData) setSuggestData({ data: returnedSuggestData });
        const query = returnedSuggestData?.suggested?.text || searchQuery;
        const { data } = await runSearchQuery({ searchQuery: query, signal });

        setSearchString(searchQuery);

        if (!data.results.length) {
          setSearchData(undefined);
          setIsLoading(false);
          return;
        }

        if (data.results.length > 0) {
          const productsFromShopify = await GetProducts({
            first: data.results.length,
            query: data.results.map((p) => `id:${p.uid}`).join(' OR '),
            country: getCustomerCountry(),
          });

          data.results.forEach((product) => {
            const productEdges = productsFromShopify?.data?.products.edges || [];
            const productNode = productEdges.find((p) => p.node.id.split('/').pop() === product.uid);
            if (!productNode) return;

            product.price = productNode.node.priceRange.maxVariantPrice.amount;
            product.variant_compare_at_price = productNode.node.compareAtPriceRange.maxVariantPrice.amount;
            product.currency = productNode.node.priceRange.maxVariantPrice.currencyCode;

            const images = productNode.node.images.edges.map((image) => image.node.url);
            product.images = images;
            product.imageUrl = images[0];
          });
        }

        setSearchData(data.results);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    })(searchQuery);

    return () => controllerRef.current?.abort();
  };

  const { data: getCartQueryResults } = useGetCart({
    refreshKey: cartUpdatedKey || '',
    cartId: cartID || '',
  });

  useMemo(() => {
    setIsAnimating(opened);
    if (!opened) return;
    setSearchString('');
    setSearchData(undefined);

    // grabbing recent searches from cookie
    setRecentSearches(getCookie('recentSearches'));

    (async function () {
      setIsLoading(true);
      setTopSellerProducts(undefined);

      try {
        const recommendationsResponse = await getPersonalizedRecommendations({
          tags: 'search-modal-trending',
          categories: `${bestSellerCollectionId}`,
          shopper: getCookie('shopper'),
          cart: getCookie('cart'),
          lastViewed: getCookie('lastViewed'),
          limits: 4,
        });

        if (recommendationsResponse.data.length > 0) {
          const newTopSellerProducts = mapToSearchProducts(recommendationsResponse.data[0].results);

          const productsFromShopify = await GetProducts({
            first: newTopSellerProducts.length,
            query: newTopSellerProducts.map((p) => `id:${p.uid}`).join(' OR '),
            country: getCustomerCountry(),
          });

          newTopSellerProducts.forEach((product) => {
            const productEdges = productsFromShopify?.data?.products.edges || [];
            const productNode = productEdges.find((p) => p.node.id.split('/').pop() === product.uid);
            if (!productNode) return;

            product.price = productNode.node.priceRange.maxVariantPrice.amount;
            product.currency = productNode.node.priceRange.maxVariantPrice.currencyCode;
            product.variant_compare_at_price = productNode.node.compareAtPriceRange.maxVariantPrice.amount;

            const images = productNode.node.images.edges.map((image) => image.node.url);
            product.images = images;
            product.imageUrl = images[0];
          });

          setTopSellerProducts(newTopSellerProducts);
        }

        setIsLoading(false);
      } catch {
        //
      }
    })();
  }, [opened, state.user.id, bestSellerCollectionId]);

  React.useMemo(() => {
    if (!cartUpdatedKey) return;
    if (getCartQueryResults?.cart?.cartLines && getCartQueryResults.cart.cartLines.length) {
      const cartString = getCartQueryResults.cart.cartLines.map((cartProduct) => cartProduct.sku).join(',');

      setCookie('cart', cartString);
    }
  }, [cartUpdatedKey, getCartQueryResults]);

  const giftCardSearchCheck = useMemo(
    () => (input: string, url: string) => {
      if (input.toLowerCase().includes('gift') && input.toLowerCase().includes('card') && env.FEATURE_GIFT_CARDS) {
        return '/products/gift-card';
      } else {
        return url;
      }
    },
    [],
  );

  const body = (
    <Container className="px-0 md:max-w-[909px]">
      <div className="md:hidden">
        <div className="px-3 pb-[0.63rem] border-b-[0.6px] border-black">
          <SearchForm
            isLoading={isLoading}
            searchString={searchString}
            setSearchString={setSearchString}
            setSearchData={setSearchData}
            setIsLoading={setIsLoading}
            onClose={onClose}
            runSearchQuery={runSearchQuery}
            handleResults={handleResults}
            giftCardSearchCheck={giftCardSearchCheck}
            ref={ref}
          />
        </div>
      </div>
      <div className="hidden md:block">
        <Transition mounted={!isAnimating} transition="fade">
          {(styles) => (
            <div
              className="px-3 pb-[0.63rem] border-b-[0.6px] border-black md:pb-0 md:px-0 md:border-b-0"
              style={isDesktop ? styles : {}}
            >
              <SearchForm
                isLoading={isLoading}
                searchString={searchString}
                setSearchString={setSearchString}
                setSearchData={setSearchData}
                setIsLoading={setIsLoading}
                onClose={onClose}
                runSearchQuery={runSearchQuery}
                handleResults={handleResults}
                giftCardSearchCheck={giftCardSearchCheck}
              />
            </div>
          )}
        </Transition>
      </div>
      <Transition mounted={!isAnimating} transition="fade">
        {(styles) => (
          <Container className="px-3 md:px-0" style={styles}>
            {!isLoading && !searchData?.length && searchString && (
              <div className="mt-6">
                <div className="text-sm tracking-[0.55px] font-normal overflow-hidden text-ellipsis">
                  No results found for {`"${searchString}"`}
                </div>
                <div className="text-[13px] tracking-[0.55px] font-m uppercase mt-6">Tips for better results</div>
                <ul className="space-y-2 mt-2">
                  <li className="text-[13px] tracking-[0.55px] font-normal">Check your spelling</li>
                  <li className="text-[13px] tracking-[0.55px] font-normal">Use simple keywords</li>
                  <li className="text-[13px] tracking-[0.55px] font-normal">Search by product type or brand</li>
                </ul>
              </div>
            )}

            {suggestData?.data && suggestData.data.suggested && suggestData.data.alternatives && (
              <Transition
                timingFunction="ease-out"
                transition={slideUpAfter('0s')}
                mounted={
                  !isLoading &&
                  Boolean(searchString) &&
                  Boolean(suggestData) &&
                  Boolean(suggestData.data.suggested) &&
                  Boolean(suggestData.data.alternatives)
                }
              >
                {(styles) => (
                  <div
                    style={styles}
                    className="pt-[0.88rem] pb-[1.12rem] border-b border-[#BDBDBD] md:pr-6 md:pt-4 md:pb-0 md:border-b-0 md:flex-1"
                  >
                    <ul className="md:pl-[50px] flex flex-col gap-y-2">
                      {suggestData.data.suggested?.text && (
                        <li>
                          <Link
                            href={giftCardSearchCheck(
                              suggestData.data.suggested?.text,
                              `/search/products/${suggestData.data.suggested?.text}`,
                            )}
                            className="flex items-center cursor-pointer gap-x-[0.37rem]"
                          >
                            <CompactSearchIcon className="w-4 h-4 min-w-4" />
                            <span>
                              {suggestData.data.suggested?.text
                                .split(new RegExp(`(${searchString})`, 'gi'))
                                .map((part, i) =>
                                  part.toLowerCase() === searchString?.toLowerCase() ? (
                                    <span key={i} className="font-bold">
                                      {part}
                                    </span>
                                  ) : (
                                    part
                                  ),
                                )}
                            </span>
                          </Link>
                        </li>
                      )}
                      {suggestData.data.alternatives.map((alternatives) => {
                        if (alternatives?.text) {
                          const url = giftCardSearchCheck(alternatives?.text, `/search/products/${alternatives.text}`);
                          return (
                            <li key={`${alternatives.text}-${Math.random().toString(20).substring(2, 15)}`}>
                              <Link
                                href={url}
                                className="flex items-center cursor-pointer gap-x-[0.37rem]"
                                onClick={() => {
                                  const recentSearches = getCookie('recentSearches')
                                    .split(',')
                                    .filter((item) => item !== '')
                                    .toSpliced(7)
                                    .toString();
                                  setCookie('recentSearches', alternatives.text + ',' + recentSearches);
                                }}
                              >
                                <CompactSearchIcon className="w-4 h-4 min-w-4" />
                                <span>
                                  {alternatives.text.split(new RegExp(`(${searchString})`, 'gi')).map((part, i) =>
                                    part.toLowerCase() === searchString?.toLowerCase() ? (
                                      <span key={i} className="font-bold">
                                        {part}
                                      </span>
                                    ) : (
                                      part
                                    ),
                                  )}
                                </span>
                              </Link>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                )}
              </Transition>
            )}

            <Transition
              timingFunction="ease-out"
              transition={slideUpAfter('0.2s')}
              mounted={!isLoading && Boolean(searchString) && Boolean(searchData)}
            >
              {(styles) => (
                <div style={styles}>
                  <ProductGrid
                    className="mt-[34px]"
                    title={`Top results for ${suggestData?.data.suggested?.text || searchString || ''}`}
                    viewAllLink={`/search/products/${suggestData?.data.suggested?.text || searchString}`}
                    products={searchData || []}
                    trackingDisabled
                  />
                </div>
              )}
            </Transition>

            <Transition
              timingFunction="ease-out"
              transition={slideUpAfter('0s')}
              mounted={!isLoading && !Boolean(searchData) && !isAnimating}
            >
              {(styles) => (
                <div className="md:flex md:pt-4" style={styles}>
                  {!!recentSearches && !!recentSearches.length && (
                    <div className="pt-[0.88rem] pb-[1.12rem] border-b border-[#BDBDBD] md:pr-6 md:pt-0 md:pb-0 md:border-b-0 md:flex-1">
                      <h4 className="text-sm font-bold uppercase pb-[0.81rem]">Recent Searches</h4>
                      <ul className="flex flex-col gap-y-2">
                        {recentSearches.split(',').map((recentSearch) => {
                          if (recentSearch) {
                            const url = giftCardSearchCheck(recentSearch, `/search/products/${recentSearch}`);
                            return (
                              <li key={`${recentSearch}-${Math.random().toString(20).substring(2, 15)}`}>
                                <Link href={url} className="flex items-center cursor-pointer gap-x-[0.37rem]">
                                  <HistoryClockIcon className="w-4 h-4" />
                                  <span>{recentSearch}</span>
                                </Link>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  )}

                  {searchTrendingLinks?.length > 0 && (
                    <div
                      className={cn(
                        'border-b border-[#BDBDBD] md:border-b-0 md:flex-1',
                        !recentSearches && !recentSearches.length
                          ? 'pt-[0.88rem] pb-[1.12rem] md:pr-6 md:pt-0 md:pb-0 md:border-r'
                          : 'py-[1.12rem] md:px-6 md:py-0 md:border-x',
                      )}
                    >
                      <h4 className="text-sm font-bold uppercase pb-[0.81rem]">Trending</h4>
                      <ul className="grid grid-cols-2 md:grid-cols-1 gap-y-2 gap-x-1 flex-wrap">
                        {searchTrendingLinks.map((trendingSearchLink) => {
                          const url = giftCardSearchCheck(trendingSearchLink.title, trendingSearchLink.href);

                          return (
                            <li key={url}>
                              <Link
                                href={url}
                                className="flex items-center cursor-pointer gap-x-[0.31rem] md:gap-x-[0.31rem]"
                              >
                                <TrendingIcon className="w-4 h-4" />
                                <span className="whitespace-nowrap">{trendingSearchLink.title}</span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}

                  {!!searchHelpLinks && !!searchHelpLinks.length && (
                    <div className="py-[1.12rem] md:pl-6 md:py-0 md:flex-1">
                      <h4 className="text-sm font-bold uppercase pb-[0.81rem]">Help</h4>
                      <ul className="flex flex-col gap-y-2">
                        {searchHelpLinks.map((searchHelpLink) => (
                          <li key={`${searchHelpLink.title}-${Math.random().toString(20).substring(2, 15)}`}>
                            <Link
                              href={searchHelpLink.href}
                              className="flex items-center cursor-pointer gap-x-[0.31rem] md:gap-x-[0.31rem]"
                            >
                              <searchHelpLink.icon className="w-4 h-4" />
                              <span className="whitespace-nowrap">{searchHelpLink.title}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </Transition>

            {!!isDesktop && (
              <Transition
                duration={300}
                mounted={!isLoading && !searchString && !searchData && Boolean(topSellerProducts)}
                timingFunction="ease-out"
                transition={slideUpAfter('0.4s')}
              >
                {(styles) => (
                  <div className="mt-[34px]" style={styles}>
                    <ProductGrid
                      title="TOP SELLERS"
                      viewAllLink="/collections/best-sellers/"
                      products={topSellerProducts || []}
                      searchSpringTag="search-modal-trending"
                      searchSpringPlacement="other"
                    />
                  </div>
                )}
              </Transition>
            )}
          </Container>
        )}
      </Transition>
    </Container>
  );

  return (
    <Modal
      body={body}
      opened={opened}
      transitionProps={{
        duration: isDesktop ? 200 : 1,
        transition: 'fade',
        onEntered: () => setIsAnimating(false),
      }}
      onClose={() => {
        onClose();
      }}
      fullScreen
      centered
      size="100vw"
      withCloseButton={false}
      classNames={{
        root: 'absolute z-[1001]',
        inner: `p-0 block !md:top-0`,
        content: 'h-full max-h-none rounded-none',
        body: 'pt-3 px-0 pb-4 md:p-4 md:py-[65px]',
      }}
    />
  );
});

export default SearchModal;
