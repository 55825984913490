import React from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { NextRouter, useRouter } from 'next/router';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ErrorBoundary } from 'next/dist/client/components/error-boundary';
import { ContextProvider } from '@ui/context/context';
import { themeOverride } from '@ui/components/theme';
import { Footer, Header } from '@ui/components/features';
import { NextPageWithHeader } from '@ui/types/pages';
import { ReactQueryProvider, StaticContentProvider } from '@repo/ui/providers';
import { Error, LocationDialog, NewsletterDialog } from '@ui/components/shared';
import { useOs } from '@mantine/hooks';
import { useRouteProgress } from '@ui/hooks/useRouteProgress';
import { useAuthRedirect } from '@ui/hooks/useAuthRedirect';
import staticContentRegion from '@ui/helpers/staticContentRegion';
import env from '@ui/env';
import '@repo/ui/styles.css';
import '../styles/global.css';
import { useElevarDataLayer } from '@ui/hooks/useElevarDataLayer';
import { StoreLocator } from '@ui/components/features';
import { getUpdatedPath } from '@repo/ui/helpers';
import { useScarabQueue } from '@ui/hooks/useScarabQueue';
import { useTolstoy } from '@ui/hooks/useTolstoy';
import { latoFont } from '@ui/helpers/fontHelper';

type AppPropsWithHeader = AppProps & {
  Component: NextPageWithHeader;
};

const generateTitle = (router: NextRouter) => {
  if (router.pathname.includes('product') || router.pathname.includes('collections')) {
    return;
  }

  let storeTitle = 'Hello Molly';

  switch (env.NEXT_PUBLIC_REGION) {
    case 'AU':
      storeTitle = storeTitle + ' AU';
      break;
    case 'NZ':
      storeTitle = storeTitle + ' NZ';
      break;
    case 'GB':
      storeTitle = storeTitle + ' UK';
      break;
    default:
      storeTitle = storeTitle + ' US';
      break;
  }

  if (router.pathname.includes('pages')) {
    if (router.pathname.includes('faq')) {
      return `${storeTitle} | FAQ`;
    }
    return `${storeTitle} | ${router.pathname.split('/').slice(-1).toString().toLocaleUpperCase().replace('-', ' ')}`;
  }
  if (router.pathname.includes('blog')) {
    return `${storeTitle} | Moments`;
  }
  return `${storeTitle} | Fashion`;
};

const content = staticContentRegion(env.NEXT_PUBLIC_REGION);

export default function HelloMollyApp({ Component, pageProps }: AppPropsWithHeader) {
  useElevarDataLayer();
  useScarabQueue();
  useAuthRedirect();
  useTolstoy();
  const router = useRouter();
  const [loadScript, setLoadScript] = React.useState(false);
  const headerProps = Component.header || {};
  const viewportMeta =
    useOs() === 'ios'
      ? 'minimum-scale=1, initial-scale=1, width=device-width, maximum-scale=1'
      : 'minimum-scale=1, initial-scale=1, width=device-width';

  useRouteProgress();

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleLoad = () => {
        setTimeout(() => setLoadScript(true), 1000);
        if((window as any).zE){
          (window as any).zE('messenger:set', 'zIndex', 1001);
        }
      };

      window.addEventListener('load', handleLoad);
      return () => window.removeEventListener('load', handleLoad);
    }
  }, []);

  React.useEffect(() => {
    const countryFromUrl = new URLSearchParams(location.search).get('country');
    if (countryFromUrl) localStorage.setItem('customerCountry', countryFromUrl.toUpperCase());
  }, []);

  return (
    <main className={`${latoFont.className} ${latoFont.variable}`}>
      <MantineProvider withGlobalStyles withNormalizeCSS={false} theme={themeOverride}>
        <Notifications position="top-center" zIndex={1002} />
        <ReactQueryProvider>
          <StaticContentProvider value={content}>
            <ContextProvider>
              <Head>
                <meta name="google-site-verification" content="ykA8Jv8tAnJ_xT3vzTpniKXHlJ9L8a9FrfTKYLKM-wY" />
                <meta name="viewport" content={viewportMeta} />
                {/* canonical URL conditions */}
                {!(router.asPath.includes('products') && router.asPath.includes('collections')) && (
                  <>
                    <link rel="alternate" hrefLang="x-default" href={`https://www.hellomolly.com${router.asPath}`} />
                    <link
                      rel="alternate"
                      href={`https://www.hellomolly.com${getUpdatedPath(router.asPath, 'US')}`}
                      hrefLang="en-us"
                    />
                    <link
                      rel="alternate"
                      href={`https://www.hellomolly.com.au${getUpdatedPath(router.asPath, 'AU')}`}
                      hrefLang="en-au"
                    />
                    <link
                      rel="alternate"
                      href={`https://www.hellomolly.co.nz${getUpdatedPath(router.asPath, 'NZ')}`}
                      hrefLang="en-nz"
                    />
                    {env.UK_LIVE && (
                      <link
                        rel="alternate"
                        href={`https://www.hellomolly.co.uk${getUpdatedPath(router.asPath, 'GB')}`}
                        hrefLang="en-gb"
                      />
                    )}
                  </>
                )}
                {/* canonical URL conditions */}
                {!(router.pathname === '/') &&
                  !router.pathname.includes('product') &&
                  !router.pathname.includes('collections') && <title>{generateTitle(router)}</title>}
                {router.pathname === '/' && (
                  <meta key="title" name="title" content="Fashion For Women | Official Online Store" />
                )}
              </Head>
              <StoreLocator />
              <NewsletterDialog />
              {env.FEATURE_MULTICURRENCY && <LocationDialog />}
              <Script async src="https://t.cfjump.com/tag/41444" />
              <Header {...headerProps} />
              <ErrorBoundary errorComponent={Error}>
                <Component {...pageProps} />
              </ErrorBoundary>
              <Footer />
              {loadScript && (
                <>
                  <Script
                    src={`https://cdn-widgetsrepository.yotpo.com/v1/loader/${env.NEXT_PUBLIC_YOTPO_APP_KEY}`}
                    crossOrigin="anonymous"
                    async
                  />
                  <Script
                    id="ze-snippet"
                    src="https://static.zdassets.com/ekr/snippet.js?key=27722cd3-e415-466e-ae40-ad5019545d97"
                    onLoad={() => {
                      if((window as any).zE) {
                        (window as any).zE('messenger:set', 'zIndex', 1001);
                      }
                    }}
                    async
                  />
                </>
              )}
            </ContextProvider>
          </StaticContentProvider>
        </ReactQueryProvider>
      </MantineProvider>
    </main>
  );
}
