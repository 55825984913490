import React from 'react';
import { Container } from '../../../core';
import Image from 'next/image';
import { type Video } from '@ui/providers/static-content-provider';
import HomeHeroSection, { type HomeHeroSectionProps } from './HomeHeroSection';

type HomeHeroWithVideoSectionProps = HomeHeroSectionProps & {
  desktopVideo: Video;
  mobileVideo: Video;
};

const HomeHeroWithVideoSection: React.FC<HomeHeroWithVideoSectionProps> = ({
  url,
  desktopImage,
  mobileImage,
  desktopVideo,
  mobileVideo,
}) => {
  return (
    <div className="relative block w-full">
      <HomeHeroSection url={url} desktopImage={desktopImage} mobileImage={mobileImage} />
      <div className="hidden md:block absolute inset-0 pointer-events-none">
        <Container className="md:px-28 py-16 w-full h-full max-w-full">
          <video
            autoPlay
            className="h-full w-auto"
            loop
            muted
            playsInline
            poster={desktopVideo.poster.src}
            preload="metadata"
          >
            <source src={desktopVideo.src} type="video/mp4" />
          </video>
        </Container>
      </div>
      <div className="md:hidden absolute left-1/2 transform -translate-x-1/2 bottom-[15%] pointer-events-none flex items-end w-full h-full">
        <video
          autoPlay
          className="w-full h-1/2"
          loop
          muted
          playsInline
          poster={mobileVideo.poster.src}
          preload="metadata"
        >
          <source src={mobileVideo.src} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default HomeHeroWithVideoSection;
