import React from 'react';
import NewsletterSection from '../NewsletterSection/NewsletterSection';
import { Box } from '../../../core';
import { FooterMenu } from '../../../shared';
import { FooterMenuHeadingLabels, FooterMenuLabels } from '@ui/types/mappings/FooterMenuMappings.type';
import env from '@ui/env';
import { useRouter } from 'next/router';

const Footer = (): React.ReactElement => {
  const router = useRouter();
  const isShowNewsletter = !router.pathname.includes('/account') && !router.pathname.includes('/returns');

  return (
    <Box bottom={0} className={!isShowNewsletter ? 'border-t-[0.6px] border-black' : ''}>
      {!!(env.FEATURE_NEWSLETTER && isShowNewsletter) && <NewsletterSection />}
      <FooterMenu menuHeadingLabels={FooterMenuHeadingLabels} menuLabels={FooterMenuLabels} />
    </Box>
  );
};

export default Footer;
