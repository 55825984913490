import { NextPage } from 'next';
import CatalogFeaturedGrid from '@ui/components/shared/grids/CatalogFeaturedGrid/CatalogFeaturedGrid';
import { useRouter } from 'next/router';
import React from 'react';
import { GetProduct } from '@client-shopify/gql/storefront/api/queries';
import { useQuery } from '@tanstack/react-query';
import { revalidate } from '@ui/hooks/useRevalidate';

export const Custom404: NextPage = () => {
  const router = useRouter();

  const isProductPage = React.useMemo(() => {
    const pattern = /^(\/collections\/[^/]+)?\/products\/[^/]+$/;
    return pattern.test(router.asPath);
  }, [router.asPath]);

  const productHandle = React.useMemo(() => {
    const pattern = /^(\/collections\/[^/]+)?\/products\/([^/?#]+)(?:[?#].*)?$/;
    return router.asPath.match(pattern)?.[2];
  }, [router.asPath]);

  const { data: product } = useQuery({
    enabled: isProductPage,
    queryKey: ['productPage', productHandle],
    queryFn: async () => {
      const res = await GetProduct({ handle: productHandle || '' });
      return res?.product;
    },
  });

  // Products released after the recent build will render a 404 page.
  // When that happens, the product page needs to be revalidated.

  React.useEffect(() => {
    if (product) revalidate(router.asPath);
  }, [product, router]);

  return (
    <div className="flex flex-col px-4 pt-7 pb-3 max-w-screen-xl mx-auto md:px-8 md:pt-14 md:pb-[5.5625rem]">
      <h1 className="font-semibold text-[2.625rem]/[1.2] pb-4 md:text-8xl md:pb-3">404</h1>
      <h2 className="uppercase font-semibold text-2xl pb-4 md:text-[2.5rem]/[1.2] md:pb-[2.5625rem]">
        Oh no, this page doesn&apos;t exist!
      </h2>
      <div className="pb-4 md:pb-[2.5625rem]">
        <h3 className="uppercase font-semibold text-xl/6 pb-4">
          We&apos;re sorry about the Error! Let us explain what might have gone wrong:
        </h3>
        <ul className="list-disc list-inside">
          <li>If you typed in the URL, please check for any typos.</li>
          <li>If you followed a link, it may be outdated.</li>
        </ul>
      </div>
      <CatalogFeaturedGrid title="Let us help you find what you are looking for" />
    </div>
  );
};
