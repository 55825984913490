/* eslint-disable @next/next/no-img-element */
import React from 'react';
import Link from 'next/link';
import env from '@ui/env';
import { unescape } from 'lodash';

export type Metafield = {
  id: number;
  namespace: string;
  key: string;
  value: string;
  value_type: string;
  description: string | null;
  admin_graphql_api_id: string;
};

export type Metafields = Metafield[];

function convertEncodedJsonToObject(encodedJsonString: string): Metafield[] | null {
  const decodedString = encodedJsonString
    .replace(/&quot;/g, '"')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&nbsp;/g, ' ')
    .replace(/&#39;/g, "'");
  try {
    const jsonObject = JSON.parse(decodedString);
    return jsonObject;
  } catch (error) {
    return null;
  }
}

const moveToFrontAndRemove = (siblings: { id: string; handle: string }[], currentHandle: string) =>
  [
    siblings.find((sibling) => sibling.handle === currentHandle)!,
    ...siblings.filter((sibling) => sibling.handle !== currentHandle),
  ].filter((e) => !!e);

const ProductCardSwatches = ({ swatches, currentHandle }: { swatches?: string; currentHandle: string }) => {
  const [siblings, setSiblings] = React.useState<{ id: string; handle: string }[] | null>(null);

  React.useEffect(() => {
    const siblingsArr = moveToFrontAndRemove(JSON.parse(unescape(swatches) || '[]'), currentHandle);
    setSiblings(siblingsArr);
  }, [swatches, currentHandle]);

  if (!env.FEATURE_COLOR_SWATCH || !siblings) return <></>;

  return (
    <div className="flex min-h-[18px] items-center">
      <div className="grid grid-cols-5 gap-2 justify-center pl-1">
        {siblings.slice(0, 5).map(({ id, handle }) => (
          <div
            className={`${handle === currentHandle ? 'ring-[1px] ring-offset-[1px] rounded-full ring-black color-swatch' : 'color-swatch'}`}
            key={id}
          >
            <Link
              prefetch={false}
              style={{
                margin: 'auto',
              }}
              href={`/products/${handle}`}
            >
              <img
                className="rounded-full h-[14px] w-[14px] m-auto"
                alt={handle}
                src={`https://assets.hellomolly.com/samples/${id}.webp`}
                height={20}
                width={20}
                onError={(e) => (e.target as HTMLElement).closest('.color-swatch')?.classList.add('hidden')}
              />
            </Link>
          </div>
        ))}
      </div>
      <div className="text-[10px] leading-3 pl-2">{siblings.length > 5 && `+ ${siblings.length - 5}`}</div>
    </div>
  );
};

export default ProductCardSwatches;
