import RunMutation from '../../utils/RunMutation.ts';
import { CART_ATTRIBUTE_UPDATE } from '../../documents/mutations';
import { AttributeInput } from '../../__generated__/graphql.ts';

type CartAttributeUpdateArgs = {
  cartId: string;
  attributes: AttributeInput[];
};

export async function CartAttributeUpdate({ cartId, attributes }: CartAttributeUpdateArgs) {
  const { data, error } = await RunMutation(CART_ATTRIBUTE_UPDATE, {
    cartId: cartId,
    attributes: attributes,
  });

  if (error) {
    return { data: undefined, error };
  }

  if (!data.cartAttributesUpdate?.cart) {
    return { data: undefined, error: { message: 'Cart not found' } };
  }

  const cart = {
    id: data.cartAttributesUpdate.cart.id,
    attributes: data.cartAttributesUpdate.cart.attributes,
  };

  return { cart, error };
}
