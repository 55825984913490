import env from '@ui/env';

export const FooterMenuHeadingLabels: Array<string> = ['HELLO MOLLY', 'SUPPORT', 'GET IN TOUCH'];

const FooterMenuSupportLinks = ['Shipping', 'Returns', 'Size Guide'];

if (env.FEATURE_PRODUCT_BNPL) {
  FooterMenuSupportLinks.push('Wear Now Pay Later');
}

if (env.FEATURE_UNIDAYS) {
  FooterMenuSupportLinks.push('UNiDAYS');
}

const FooterMenuGetInTouchLinks = ['Contact Us', 'FAQ'];

if (env.FEATURE_GIFT_CARDS) {
  FooterMenuGetInTouchLinks.push('Gift Cards');
}

export const FooterMenuLabels: { [key: string]: Array<string> } = {
  'HELLO MOLLY': ['About Us', 'Careers', 'Reviews', 'Blog', 'Brands', 'Competitions'],
  SUPPORT: FooterMenuSupportLinks,
  'GET IN TOUCH': FooterMenuGetInTouchLinks,
};
