import { gql } from '../../__generated__/gql';

export const CART_ATTRIBUTE_UPDATE = gql(`
 mutation cartAttributesUpdate($attributes: [AttributeInput!]!, $cartId: ID!) {
  cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
    cart {
        id
        attributes {
          key
          value
        }
    }
    userErrors {
      field
      message
    }
   
  }
}
`);
