import axios from 'axios';
import env from '@ui/env';
import { SearchSpringFacets } from '@ui/types/searchSpring/facets.type';
import { BrandsList } from '@ui/types/mappings/BrandMappings.type';
import { generateCollectionFilters } from '@ui/helpers/filter';
import { Root } from '@ui/types/mappings/Collections';

export interface ApiBreadcrumbs {
  field: string;
  filterLabel: string;
  filterValue: string;
  label: string;
  removeFilters: string[];
  removeRefineQuery: string[];
}

interface ApiValue {
  active: boolean;
  type: string;
  value?: string;
  label: string;
  count: number;
  low?: string;
  high?: string;
}
export interface ApiFacet {
  field: string;
  label: string;
  type: string;
  multiple: string;
  collapse: number;
  facet_active: number;
  values: ApiValue[];
}

export interface ApiFilterSummary {
  field: string;
  filterLabel: string;
  filterValue: string;
  label: string;
  value: string;
}

interface ApiTriggeredCampaigns {
  id: string;
  title: string;
  type: string;
}

export interface ApiMerchandising {
  content: any;
  elevated: string[];
  experiments: string[];
  facets: string[];
  facetsHide: string[];
  is_elevated: string[];
  personalized: boolean;
  redirect: string;
  removed: string[];
  triggeredCampaigns: ApiTriggeredCampaigns[];
  variants: string[];
}

export interface ApiPagination {
  begin: number;
  currentPage: number;
  defaultPerPage: number;
  end: number;
  nextPage: number;
  perPage: number;
  previousPage: number;
  totalPages: number;
  totalResults: number;
}

export interface ApiResult {
  brand: string;
  handle: string;
  id: string;
  intellisuggestData: string;
  intellisuggestSignature: string;
  mfield_product_color_options_color_options?: string;
  msrp: string;
  name: string;
  price: string;
  product_type_unigram: string;
  sku: string;
  ss_available: string;
  ss_exclude: string;
  ss_has_image: string;
  ss_id: string;
  ss_instock_pct: string;
  ss_is_published: string;
  ss_on_sale: string;
  ss_pct_off: string;
  ss_price: string;
  ss_sizes: string;
  tags: string[];
  title: string;
  uid: string;
  url: string;
  metafields: string;
  variant_compare_at_price: string;
  variant_inventory_quantity: string;
  variant_price: string;
  variant_product_id: string;
  variant_size: string[];
  variant_sku: string[];
  variant_title: string[];
  vendor: string;
  imageUrl: string;
  images: string[];
  mfield_image_type_image_back?: string;
  mfield_image_type_image_cover?: string;
  mfield_image_type_image_front?: string;
  thumbnailImageUrl?: string;
  mfield_image_type_image_closeup?: string;
  published_at: string;
  currency?: string;
  mfield_categorisation_siblings?: string;
}

interface ApiSortingOptions {
  direction: string;
  field: string;
  label: string;
}

export interface ApiSorting {
  options: ApiSortingOptions[];
}

export interface ApiSearch {
  breadcrumbs: ApiBreadcrumbs[];
  facets: ApiFacet[];
  filterSummary: ApiFilterSummary[];
  merchandising: ApiMerchandising;
  pagination: ApiPagination;
  resultLayout: string;
  results: ApiResult[];
  sorting: ApiSorting;
}

interface Value {
  active: boolean;
  value?: string;
  label: string;
  low?: string;
  high?: string;
}
interface Facet {
  label: string;
  values: Value[];
}

interface Pagination {
  totalPages: number;
  totalResults: number;
}

interface Result {
  brand: string;
  handle: string;
  id: string;
  intellisuggestData: string;
  intellisuggestSignature: string;
  msrp: string;
  price: string;
  sku: string;
  ss_id: string;
  ss_sizes?: string;
  tags: string[];
  title: string;
  uid: string;
  url: string;
  variant_compare_at_price?: string;
  variant_inventory_quantity: string;
  variant_price: string;
  variant_sku: string[];
  vendor: string;
  imageUrl: string;
  images: string[];
  published_at: string;
}

export interface SortingOptions {
  direction: string;
  field: string;
  label: string;
}

interface Sorting {
  options: SortingOptions[];
}

interface Search {
  facets: Facet[];
  results: Result[];
  pagination: Pagination;
  sorting: Sorting;
}

type searchArgs = {
  ssUserId: string;
  ssSessionIdNamespace: string;
  pageLoadId: string;
  shopper: string;
  cart: string;
  lastViewed: string;
  query?: string;
  bgfilter?: string;
  baseFilter?: string;
  filters?: Array<string>;
  sort?: string;
  resultsPerPage?: number;
  pageNumber?: number;
  bgfilterHandle?: string;
};

const search = async ({
  ssUserId,
  ssSessionIdNamespace,
  pageLoadId,
  shopper,
  cart,
  lastViewed,
  query,
  bgfilter,
  baseFilter,
  filters,
  sort,
  resultsPerPage,
  pageNumber,
  bgfilterHandle,
}: searchArgs) => {
  let queryString = '';
  let bgfilterString = '';
  let filterString = '';
  let sortString = '';
  let shopperString = '';
  let cartString = '';
  let lastViewedString = '';

  if (shopper) {
    shopperString += `&shopper=${encodeURIComponent(shopper)}`;
  }

  if (cart) {
    cartString += `&cart=${encodeURIComponent(cart)}`;
  }

  if (lastViewed) {
    lastViewedString += `&lastViewed=${encodeURIComponent(lastViewed)}`;
  }

  if (query) {
    queryString += `&q=${encodeURIComponent(query)}`;
  }

  if (bgfilter) {
    bgfilterString += `&bgfilter.collection_handle=${encodeURIComponent(bgfilter)}`;
  }

  if (bgfilterHandle) {
    bgfilterString += `&bgfilter.handle=${encodeURIComponent(bgfilterHandle)}`;
  }

  if (baseFilter) {
    filterString += `&filter.${encodeURIComponent(baseFilter)}`;
  }
  if (filters) {
    filters.forEach((filter) => {
      if (filter.split('_').shift()?.toUpperCase() !== 'PRICE') {
        filterString += `&filter.${
          SearchSpringFacets[filter.split('_').shift()?.toUpperCase() as keyof typeof SearchSpringFacets]
        }=${encodeURIComponent(filter.split('_').pop() ?? '')}`;
      } else if (filter.split('_')[2] === '*' && filter.split('_')[4] !== '*') {
        filterString += `&filter.${
          SearchSpringFacets[filter.split('_').shift()?.toUpperCase() as keyof typeof SearchSpringFacets]
        }.low=1&filter.${
          SearchSpringFacets[filter.split('_').shift()?.toUpperCase() as keyof typeof SearchSpringFacets]
        }.high=${encodeURIComponent(filter.split('_')[4])}`;
      } else if (filter.split('_')[2] !== '*' && filter.split('_')[4] === '*') {
        filterString += `&filter.${
          SearchSpringFacets[filter.split('_').shift()?.toUpperCase() as keyof typeof SearchSpringFacets]
        }.low=${encodeURIComponent(filter.split('_')[2])}`;
      } else if (filter.split('_')[2] !== '*' && filter.split('_')[4] !== '*') {
        filterString += `&filter.${
          SearchSpringFacets[filter.split('_').shift()?.toUpperCase() as keyof typeof SearchSpringFacets]
        }.low=${encodeURIComponent(filter.split('_')[2])}&filter.${
          SearchSpringFacets[filter.split('_').shift()?.toUpperCase() as keyof typeof SearchSpringFacets]
        }.high=${encodeURIComponent(filter.split('_')[4])}`;
      }
    });
  }

  if (sort) {
    sortString += `&sort.${sort}`;
  }

  function adjustTagForFinalSale(s: string): string {
    const targetSubstring: string = '&filter.tags_category=final-sale';
    const replacementSubstring: string = '&filter.tags=final-sale';

    if (s.includes(targetSubstring)) {
      return s.replace(targetSubstring, replacementSubstring);
    }
    return s;
  }

  // Exclude Tapcart specific facets from the response.
  const excludedFacets = [
    'ss_filter_brand',
    'ss_filter_category',
    'ss_filter_color',
    'ss_filter_considered',
    'ss_filter_length',
    'ss_filter_model',
    'ss_filter_occasion',
    'ss_filter_size',
    'ss_filter_sleeves',
    'ss_filter_trend',
    'ss_filter_price',
    'ss_days_since_created',
    'ss_days_since_published',
  ]
    .map((facet) => `excludedFacets=${facet}`)
    .join('&');

  try {
    const { data } = await axios.request<ApiSearch>({
      method: 'GET',
      url: `${env.NEXT_PUBLIC_BASE_SEARCHSPRING_URL}api/search/search.json?siteId=${
        env.NEXT_PUBLIC_SEARCHSPRING_SITEID
      }&userId=${ssUserId}${shopperString}${cartString}${lastViewedString}&sessionId=${ssSessionIdNamespace}&domain=${
        env.NEXT_PUBLIC_BASE_URL
      }&pageLoadId=${pageLoadId}&resultsFormat=json${queryString}${bgfilterString}${adjustTagForFinalSale(
        filterString,
      )}${sortString}&resultsPerPage=${resultsPerPage || 72}&page=${pageNumber || 1}&${excludedFacets}&redirectResponse=full`,
      headers: {
        accept: 'application/json',
      },
    });

    return { data };
  } catch (err) {
    throw new Error(JSON.stringify(err));
  }
};

export default search;

export async function searchCollection({
  collectionName,
  collectionFilter,
  pageType,
  pageNumber,
  sort,
  ssUserId = '',
  ssSessionIdNamespace = '',
  pageLoadId = '',
  shopper = '',
  cart = '',
  lastViewed = '',
}: {
  collectionName: string;
  collectionFilter?: string;
  pageType: string;
  pageNumber: number;
  sort: string;
  ssUserId?: string;
  ssSessionIdNamespace?: string;
  pageLoadId?: string;
  shopper?: string;
  cart?: string;
  lastViewed?: string;
}): Promise<Root> {
  const isBrandCollection = BrandsList.includes(collectionName.replace(/-/g, ' ').toUpperCase());

  let searchFilters: Array<string> = [];

  if (pageType === 'category' && collectionFilter) {
    searchFilters = generateCollectionFilters(collectionFilter);
  } else if (isBrandCollection) {
    searchFilters = [`brand_${collectionName}`];
  }

  const { data } = await search({
    ssUserId,
    ssSessionIdNamespace,
    pageLoadId,
    shopper,
    cart,
    lastViewed,
    bgfilter: isBrandCollection ? undefined : collectionName,
    filters: searchFilters,
    pageNumber,
    sort,
  });

  return {
    query: '',
    collection: collectionName,
    facets: data.facets,
    totalPages: data.pagination.totalPages,
    totalResults: data.pagination.totalResults,
    results: data.results,
    sortingOptions: data.sorting.options,
    filters: data.filterSummary.map((filter) => (filter.filterLabel + '_' + filter.filterValue).toLowerCase()),
    page: pageNumber,
    sort,
  };
}
