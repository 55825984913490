export const checkPromoEligible = (tags: string[]): true | undefined => {
  if (!tags.length) return undefined;
  if (tags.includes('promo-eligible')) return true;
  return undefined;
};

// black friday promo badge
export const getProductBadge = (productTags: string[]): string | undefined => {
  // TODO: remove this once we have the new promo badges
  return undefined;
  if (productTags.includes('category_promo-20')) {
    return 'WOW! 20% Off';
  } else if (productTags.includes('category_promo-50')) {
    return 'YAY! 50% Off';
  } else if (productTags.includes('category_promo-80')) {
    return 'OMG! 80% Off';
  } else {
    return undefined;
  }
};

export const isOnSale = (price: string, compareAtPrice?: string) => {
  const actualPrice = parseFloat(price).toFixed(2);
  const comparePrice = compareAtPrice ? parseFloat(compareAtPrice).toFixed(2) : '0.00';
  return comparePrice !== '0.00' && comparePrice !== actualPrice;
};
