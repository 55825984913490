import { CartAttributeUpdate } from '@client-shopify/gql/storefront/api/mutations';
import { useCartStore } from '@ui/store.export';
import { useEffect, useCallback } from 'react';

type TolstoyMessage = {
  name: string;
  anonymousId: string;
};

const TOLSTOY_EVENT_NAME = 'tolstoyStarted';
const TOLSTOY_ATTRIBUTE_KEY = '__tolstoyAnonymousId';
const TOLSTOY_STORAGE_KEY = 'tolstoyAnonymousId';

const updateCartWithTolstoyId = async (cartId: string, anonymousId: string) => {
  try {
    const response = await CartAttributeUpdate({
      cartId,
      attributes: [{ key: TOLSTOY_ATTRIBUTE_KEY, value: anonymousId }],
    });
    return response;
  } catch (error) {}
};

export function useTolstoy() {
  const cartID = useCartStore((state) => state?.cartId);

  const handleTolstoyMessage = useCallback(
    async (event: MessageEvent) => {
      const data = event.data as TolstoyMessage;

      if (data?.name !== TOLSTOY_EVENT_NAME) {
        return;
      }
      localStorage.setItem(TOLSTOY_STORAGE_KEY, data.anonymousId);

      if (cartID) {
        await updateCartWithTolstoyId(cartID, data.anonymousId);
      }
    },
    [cartID],
  );

  useEffect(() => {
    const messageHandler = (event: MessageEvent<TolstoyMessage>) => {
      if (cartID) {
        handleTolstoyMessage(event);
      } else if (event.data?.name === TOLSTOY_EVENT_NAME) {
        localStorage.setItem(TOLSTOY_STORAGE_KEY, event.data.anonymousId);
      }
    };

    const storedAnonymousId = localStorage.getItem(TOLSTOY_STORAGE_KEY);
    if (cartID && storedAnonymousId) {
      updateCartWithTolstoyId(cartID, storedAnonymousId);
      localStorage.removeItem(TOLSTOY_STORAGE_KEY);
    }

    window.addEventListener('message', messageHandler);
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [handleTolstoyMessage, cartID]);
}
