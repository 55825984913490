import { gql } from '../../__generated__/gql';

export const GET_PRODUCTS = gql(`
  query getProducts($first: Int!, $query: String!, $country: CountryCode!) @inContext(country: $country) {
    products(first: $first, query: $query) {
      edges {
        node {
          id
          handle
          images(first: 12) {
            edges {
              node {
                id
                url
                width
                height
                altText
              }
            }
          }
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
          }
          compareAtPriceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`);
