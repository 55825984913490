import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';
import { Cart } from '@client-shopify/gql/storefront/api/queries/GetCart';
import { CustomerAccessTokenDelete } from '@client-shopify/gql/storefront/api/mutations';
import { v4 as uuidv4 } from 'uuid';
import { useElementSize, useClickOutside } from '@mantine/hooks';
import { setCookie } from '@ui/hooks/useCookie';
import { authSelectors } from '@ui/store/authStore';
import { wishlistSelectors } from '@ui/store/wishlistStoreAsync';
import useYotpoDataLayer from '@ui/hooks/useYotpoDataLayer';

import { Banner, BannerBFCM } from '@ui/components/features';
import {
  CartDrawer,
  MegaMenuDrawer,
  MegaMenuPopover,
  NotificationBadge,
  SearchModal,
  WishlistDrawer,
  Button,
} from '@ui/components/shared';
import {
  AccountIcon,
  WishlistIcon,
  BagIcon,
  ActionIcon,
  Popover,
  CompactSearchIcon,
  Container,
  Logo,
  LogoCircleIcon,
  MenuIcon,
  SearchIcon,
  CompactCloseIcon,
  AccountLogOutIcon,
} from '@ui/components/core';
import { HeaderProps } from '@ui/types/pages';
import { UserAction } from '@ui/types/actions';
import { AppContext } from '@ui/context/context';
import useCartStore from '@ui/store/cartStore';
import Link from 'next/link';
import { sumBy, throttle } from 'lodash';
import useModalStore from '@ui/store/modalStore';
import cn from '@ui/utils/cn';
import { useGetCart } from '@ui/hooks/useCartQuery';
import { isWishlistFrontEnd } from '@ui/helpers/isWishlistFrontEnd';
import type { WishlistFrontEnd } from '@ui/types/contextObjects';
import { useStaticContent } from '@ui/providers/static-content-provider';
import { AccountNavLinks } from '@ui/types/mappings/AccountNavMappings.type';

const hasWishlistItems = (wishlist: {} | WishlistFrontEnd) => {
  if (isWishlistFrontEnd(wishlist)) {
    return !!Object.keys(wishlist.products).length;
  }

  return false;
};

const onSearchClick = () => {
  // create invisible dummy input to receive the focus first
  const fakeInput = document.createElement('input');
  fakeInput.setAttribute('type', 'text');
  fakeInput.style.position = 'absolute';
  fakeInput.style.opacity = '0';
  fakeInput.style.height = '0';
  fakeInput.style.fontSize = '16px';
  document.body.prepend(fakeInput);
  fakeInput.focus();

  setTimeout(() => {
    const searchForm: HTMLInputElement | null = document.querySelector('[data-searchform]');
    if (!searchForm) return;
    fakeInput.blur();
    searchForm.focus();
    searchForm.click();
    fakeInput.remove();
  }, 1000);
};

const Header = ({ title, layout, backIcon }: HeaderProps): React.ReactElement => {
  const { yotpoCustomerIdentifcation } = useYotpoDataLayer();
  const router = useRouter();
  const { auth, persistantAuth } = authSelectors;
  const { wishlistStore } = wishlistSelectors;
  const loggedIn = auth.use.isLoggedIn();
  const token = persistantAuth.use.token();
  const login = auth.use.login();
  const logout = auth.use.logout();
  const setToken = persistantAuth.use.setToken();
  const customerId = auth.use.customerId();
  const customer = auth.use.customer();
  const { state, dispatch } = React.useContext(AppContext);
  const cartId = useCartStore((state) => state?.cartId);
  const setCartId = useCartStore((state) => state?.setCartId);
  const cartUpdatedKey = useCartStore((state) => state?.cartUpdatedKey);
  const setCartUpdatedKey = useCartStore((state) => state?.setCartUpdatedKey);
  const getWishlist = wishlistStore.use.getWishlist();
  const setWishlistSession = wishlistStore.use.setWishlistSession();
  const wishlist = wishlistStore.use.wishlist();
  const openedModal = useModalStore((state) => state.openedModal);
  const openModal = useModalStore((state) => state.openModal);
  const closeModal = useModalStore((state) => state.closeModal);
  const searchFormRef = React.useRef<HTMLInputElement>();
  const [isLoggingOut, setIsLoggingOut] = React.useState(false);
  const [popoverOpened, setPopoverOpened] = React.useState(false);
  const popoverRef = useClickOutside(() => setPopoverOpened(false));
  const timeoutRef = React.useRef<NodeJS.Timeout>();

  React.useEffect(() => {
    if (token) {
      login(token as string);
    }
  }, [token, login]);

  React.useEffect(() => {
    (async () => {
      await getWishlist(customerId);
    })();
    if (customer?.customer?.email) {
      yotpoCustomerIdentifcation(customer?.customer?.email);
    }
  }, [customerId, getWishlist, customer?.customer?.email, yotpoCustomerIdentifcation]);

  const { data: getCartQueryResults } = useGetCart({
    refreshKey: cartUpdatedKey || '',
    cartId: cartId || '',
  });

  React.useEffect(() => {
    if (!router.query['cart-abandonment']) return;
    if (!cartId) {
      setCartId?.(router.query['cart-abandonment'] as string);
      setCartUpdatedKey?.();
    }
  }, [router.query, getCartQueryResults, cartId, openModal, setCartId, setCartUpdatedKey]);

  const desktopMenuLinkTree = useStaticContent('Menu.DesktopMenuLinkTree');
  const [cartAnimate, setCartAnimate] = React.useState<boolean>(false);
  const [cartItemCount, setCartItemCount] = React.useState(0);
  function storePathValues() {
    const storage = globalThis.sessionStorage;

    if (!storage) return;

    const prevPath = storage.getItem('currentPath');

    storage.setItem('previousPath', prevPath || globalThis.location.pathname);
    storage.setItem('currentPath', globalThis.location.pathname);
  }

  function handleBackPress() {
    const storage = globalThis.sessionStorage;

    const previousPath = storage.getItem('previousPath') || '';
    const currentPath = storage.getItem('currentPath') || '';
    const authPaths = ['/account/login', '/account/signup/'];

    if (previousPath === currentPath) {
      router.push('/');
    } else if (authPaths.includes(previousPath)) {
      router.push('/');
    } else {
      router.back();
    }
  }

  React.useEffect(() => {
    storePathValues();
    setCookie('pageLoadId', uuidv4());
  }, [router.asPath]);

  React.useEffect(() => {
    const cartItemsLength: number = getCartQueryResults?.cart?.cartLines?.length || 0;
    const dynamicCartItemCount = cartItemCount;
    const newCartItemCount = sumBy(Object.values(dynamicCartItemCount), 'quantity');
    setCartAnimate(dynamicCartItemCount < newCartItemCount);
    setCartItemCount(cartItemsLength);
  }, [cartUpdatedKey, cartItemCount, getCartQueryResults?.cart?.cartLines?.length]);

  // The header shrinks at 94px down the page, however the shrinkking actually changes the scrollTop as the USP bar is hidden entirely. The TOLERANCE value is to provide a buffer so that the header doesn't continually flicker between hidden and shown states at a certain scroll point.
  React.useEffect(() => {
    const TOLERANCE = 32;
    const onScroll = throttle(() => {
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      const isShrunk = document.body.scrollTop > 94 + TOLERANCE || document.documentElement.scrollTop > 94 + TOLERANCE;

      if (isShrunk) {
        document.documentElement.setAttribute('data-shrunk', 'true');
      } else if (scrollTop < 94 - TOLERANCE) {
        document.documentElement.removeAttribute('data-shrunk');
      }
    }, 100);

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const { ref, height } = useElementSize();

  React.useEffect(() => {
    document.documentElement.style.setProperty('--header-height', `${height + 1}px`);
  }, [height]);

  React.useEffect(() => {
    router.events.on('routeChangeStart', closeModal);
    return () => router.events.off('routeChangeStart', closeModal);
  }, [router.events, closeModal]);

  return (
    <>
      <header
        ref={ref}
        className={cn(
          'sticky top-0 bg-white border-b-[0.6px] border-black z-[1001] delay-75',
          'lg:shrunk:translate-y-[calc(var(--banner-height)-var(--menu-height))]  shrunk:translate-y-[calc(var(--banner-height)-var(--menu-height))] shrunk:duration-500',
          'transition duration-700 ease-out',
        )}
      >
        <div
          className={cn(
            'translate-y-0 opacity-100 delay-75',
            'shrunk:-translate-y-full shrunk:opacity-0 shrunk:duration-500',
            'transition duration-500 ease-out',
          )}
          data-date-range="date-range"
          data-banner
        >
          <Banner />
        </div>

        <Container className="hidden lg:block relative">
          <PolymorphicLogoComponent urlPath={router.pathname}>
            <div
              className={cn(
                'flex justify-center mt-[18px]',
                'translate-y-0 opacity-100',
                'transition duration-700 ease-out shrunk:mt-[0px]',
              )}
            >
              <Logo height={50} width={160} />
            </div>
            <div
              className={cn(
                'flex items-center justify-between shrink-0',
                'w-[9.25rem]',
                'transition-[width] duration-700',
                '[&_svg:hover]:scale-[1.1] absolute top-[18px] right-8',
              )}
            >
              <ActionIcon
                variant="transparent"
                onClick={() => {
                  openModal('search');
                  onSearchClick();
                }}
                data-modal-toggle
              >
                <SearchIcon height={28} width={28} />
              </ActionIcon>
              <div ref={popoverRef}>
                <Popover
                  opened={popoverOpened}
                  withArrow
                  withinPortal
                  position="bottom-end"
                  arrowSize={10}
                  arrowOffset={10}
                  offset={0}
                  zIndex={1003}
                  width="19.125rem"
                  classNames={{ arrow: 'border border-black' }}
                >
                  <Popover.Target>
                    <ActionIcon
                      variant="transparent"
                      onClick={() => setPopoverOpened(true)}
                      onMouseEnter={() => (timeoutRef.current = setTimeout(() => setPopoverOpened(true), 300))}
                      onMouseLeave={() => {
                        clearTimeout(timeoutRef.current);
                        setPopoverOpened(false);
                      }}
                    >
                      <AccountIcon
                        className={cn(!loggedIn ? 'fill-white' : 'fill-[#F7D6E3]', 'text-black')}
                        height={28}
                        width={28}
                      />
                    </ActionIcon>
                  </Popover.Target>

                  <Popover.Dropdown
                    className="p-2 uppercase border-[0.6px] border-black"
                    onMouseEnter={() => setPopoverOpened(true)}
                    onMouseLeave={() => setPopoverOpened(false)}
                  >
                    {loggedIn && customer && customer.customer ? (
                      <div className="flex flex-col gap-y-2">
                        <div className="border-[0.6px] border-black rounded-[0.25rem] bg-pink-2 text-base py-2 px-4 flex justify-start items-center gap-x-4">
                          <LogoCircleIcon height={38} width={38} />
                          <div className="flex flex-col">Hello, {customer.customer?.firstName}</div>
                        </div>
                        <div className="flex flex-col gap-y-2 pb-2 border-b-[0.6px] border-[#B3B3B3] text-sm">
                          {AccountNavLinks.header.loggedIn.map(({ IconComponent, href, label }) => (
                            <Link
                              href={href}
                              key={`header-${href}`}
                              className="flex justify-start items-center gap-x-2 py-[0.62rem] px-[0.75rem] text-sm hover:bg-btn-secondary-selected rounded-[0.25rem]"
                              onClick={() => setPopoverOpened(false)}
                            >
                              <IconComponent />
                              <span>{label}</span>
                            </Link>
                          ))}
                        </div>
                        <div>
                          <Button
                            className="uppercase text-sm text-left no-underline w-full border-none flex justify-start items-center gap-x-2 py-[0.62rem] px-[0.75rem] hover:bg-btn-secondary-selected rounded-[0.25rem]"
                            variant="unstyled"
                            disabled={isLoggingOut}
                            loading={isLoggingOut}
                            onClick={async () => {
                              setIsLoggingOut(true);

                              try {
                                CustomerAccessTokenDelete({
                                  customerAccessToken: token ?? '',
                                })
                                  .then()
                                  .catch((err) => {
                                    throw new Error('Invalid token:' + JSON.stringify(err));
                                  });
                              } catch {
                                //
                              }

                              dispatch({
                                type: UserAction.Logout,
                                payload: {
                                  id: token ?? '',
                                },
                              });

                              setToken(null);
                              logout();
                              setWishlistSession();
                              setPopoverOpened(false);
                              router.push('/');
                            }}
                          >
                            <AccountLogOutIcon height={24} width={24} />
                            <span>LOG OUT</span>
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col gap-y-2">
                        <div className="flex flex-col gap-y-2 pb-2 border-b-[0.6px] border-[#B3B3B3]">
                          {AccountNavLinks.header.loggedOut.top.map(({ IconComponent, href, label }) => (
                            <Link
                              href={href}
                              key={`header-${href}`}
                              className="flex justify-start items-center gap-x-2 py-[0.62rem] px-[0.75rem] text-sm hover:bg-btn-secondary-selected rounded-[0.25rem]"
                              onClick={() => setPopoverOpened(false)}
                            >
                              <IconComponent />
                              <span>{label}</span>
                            </Link>
                          ))}
                        </div>
                        <div>
                          {AccountNavLinks.header.loggedOut.bottom.map(({ IconComponent, href, label }) => (
                            <Link
                              href={href}
                              key={`header-${href}`}
                              className="flex justify-start items-center gap-x-2 py-[0.62rem] px-[0.75rem] text-sm hover:bg-btn-secondary-selected rounded-[0.25rem]"
                              onClick={() => setPopoverOpened(false)}
                            >
                              <IconComponent />
                              <span>{label}</span>
                            </Link>
                          ))}
                        </div>
                      </div>
                    )}
                  </Popover.Dropdown>
                </Popover>
              </div>

              <ActionIcon variant="transparent" onClick={() => openModal('wishlist')} data-modal-toggle>
                <NotificationBadge
                  className="size-[0.8rem] mt-[0.4rem] mr-[0.25rem]"
                  color="brand.8"
                  show={hasWishlistItems(wishlist)}
                  animate={false}
                >
                  <WishlistIcon width={28} height={28} fill={hasWishlistItems(wishlist) ? '#F7D6E3' : 'white'} />
                </NotificationBadge>
              </ActionIcon>
              <ActionIcon variant="transparent" onClick={() => openModal('cart')} data-modal-toggle>
                <NotificationBadge
                  className="size-[1.3rem] mt-[0.25rem] mr-[0.25rem]"
                  color="brand.9"
                  label={cartItemCount.toString()}
                  show={cartItemCount > 0}
                  animate={cartAnimate}
                >
                  <BagIcon
                    width={28}
                    height={28}
                    className={cn(
                      'transition-all ease-in duration-200',
                      cartItemCount > 0 ? 'fill-[#F7D6E3]' : 'fill-white',
                      cartAnimate && 'animate-grow',
                    )}
                  />
                </NotificationBadge>
              </ActionIcon>
            </div>
          </PolymorphicLogoComponent>
          <div className={cn('flex mt-6 h-[33px]', 'transition duration-500 ease-out')}>
            {/* <div className="w-0 transition-[width] duration-700" /> */}

            <div className="relative flex-1">
              <div className="flex justify-between h-full">
                {desktopMenuLinkTree?.map((menuLink) => <MegaMenuPopover key={menuLink.label} menuLink={menuLink} />)}
              </div>
            </div>
          </div>
        </Container>

        <Container className="lg:hidden">
          {layout === 'with-back-button-and-title' ? (
            <div className="flex items-end justify-center pt-4 pb-3 h-[60px]">
              <ActionIcon
                className={cn('absolute left-2', backIcon ? 'translate-y-[3px]' : 'translate-y-2')}
                variant="transparent"
                onClick={handleBackPress}
              >
                {backIcon || <CompactCloseIcon height={14} width={14} />}
              </ActionIcon>
              <h1 className="font-bold text-sm uppercase">{title}</h1>
            </div>
          ) : (
            <>
              <div className="flex items-end justify-between pt-4 pb-3">
                <div className="flex items-end space-x-2 translate-y-1">
                  {layout === 'with-back-button' ? (
                    <ActionIcon variant="transparent" onClick={handleBackPress}>
                      <CompactCloseIcon height={14} width={14} />
                    </ActionIcon>
                  ) : (
                    <ActionIcon variant="transparent" onClick={() => openModal('megamenu')} data-modal-toggle>
                      <MenuIcon height={28} width={28} />
                    </ActionIcon>
                  )}
                  <ActionIcon
                    variant="transparent"
                    onClick={() => {
                      openModal('search');
                      onSearchClick();
                      // searchFormRef.current?.click();
                      // searchFormRef.current?.focus();
                    }}
                    data-modal-toggle
                    className={cn(
                      !router.asPath.includes('/products/') &&
                        'opacity-0 shrunk:opacity-100 transition duration-500 ease-out',
                    )}
                  >
                    <SearchIcon height={24} width={24} />
                  </ActionIcon>
                </div>
                <PolymorphicLogoComponent urlPath={router.pathname}>
                  <Logo height={32} width={125.83} />
                </PolymorphicLogoComponent>
                <div className="flex items-end space-x-2 translate-y-1">
                  <ActionIcon variant="transparent" onClick={() => openModal('wishlist')} data-modal-toggle>
                    <NotificationBadge
                      className="size-[0.8rem] mt-[0.4rem] mr-[0.25rem]"
                      color="brand.8"
                      show={hasWishlistItems(wishlist)}
                      animate={false}
                    >
                      <WishlistIcon width={28} height={28} fill={hasWishlistItems(wishlist) ? '#F7D6E3' : 'white'} />
                    </NotificationBadge>
                  </ActionIcon>
                  <ActionIcon variant="transparent" onClick={() => openModal('cart')} data-modal-toggle>
                    <NotificationBadge
                      className="size-[1.3rem] mt-[0.25rem] mr-[0.25rem]"
                      color="brand.9"
                      label={cartItemCount.toString()}
                      show={cartItemCount > 0}
                      animate={cartAnimate}
                    >
                      <BagIcon
                        width={28}
                        height={28}
                        className={cn(
                          'transition-all ease-in duration-200',
                          cartItemCount > 0 ? 'fill-[#F7D6E3]' : 'fill-white',
                          cartAnimate && 'animate-grow',
                        )}
                      />
                    </NotificationBadge>
                  </ActionIcon>
                </div>
              </div>
              {!router.asPath.includes('/products/') && (
                <div
                  className={cn(
                    openedModal === 'search' ? 'opacity-0' : 'opacity-100',
                    'pb-[0.63rem] delay-75 block',
                    'shrunk:-translate-y-full shrunk:opacity-0 shrunk:duration-500 shrunk:hidden shrunk:pb-0',
                    'transition duration-700 ease-out overflow-hidden',
                  )}
                  style={{ transitionDelay: 'allow-discrete' }}
                  data-false-search-input
                >
                  <button
                    className="block border-[0.6px] border-black rounded-[6.25rem] bg-[#FAEAF0] overflow-hidden w-full"
                    onClick={() => {
                      openModal('search');
                      onSearchClick();
                    }}
                  >
                    <div className="relative flex items-center">
                      <div className="absolute left-[0.48rem] pointer-events-none flex items-center justify-center rounded-full w-5 h-5">
                        <CompactSearchIcon className="w-4 h-4 text-[#757575]" />
                      </div>
                      <div className="bg-transparent w-full h-8 text-sm tracking-[0.03em] pl-9 flex items-center text-left focus:outline-none text-[#757575]">
                        Search
                      </div>
                    </div>
                  </button>
                </div>
              )}
            </>
          )}
        </Container>
      </header>
      <MegaMenuDrawer isGuest={!loggedIn} opened={openedModal === 'megamenu'} onClose={closeModal} />
      <SearchModal opened={openedModal === 'search'} onClose={closeModal} ref={searchFormRef} />
      <WishlistDrawer opened={openedModal === 'wishlist'} onClose={closeModal} />
      <CartDrawer
        cart={getCartQueryResults?.cart as Cart}
        cartItemCount={cartItemCount}
        opened={openedModal === 'cart'}
        onClose={closeModal}
      />
    </>
  );
};

const PolymorphicLogoComponent = ({ urlPath, children }: { urlPath: string; children: ReactNode }) => {
  if (urlPath === '/') {
    return <h1>{children}</h1>;
  }
  return <>{children}</>;
};

export default Header;
